import instance from "@/utils/useAxiosApi";

export function postLogin(params: AnyObject = {}) {
    return instance({
        url: `/mobile/user/isLogin`,
        method: 'POST',
        data: params
    });
}

export function getUserInfo(params: AnyObject = {}) {
    return instance({
        url: `/mobile/user/getUserInfo`,
        method: 'POST',
        data: params
    });
}

export function getUserInfoV1(params: AnyObject = {}) {
    return instance({
        url: process.env["VUE_APP_api2Domain"] + "/v1/user/c/info/findCurrentLoginUserInfo",
        method: "POST",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: params
    });
}

export function getUserLeaveInfo(params: AnyObject = {}) {
    return instance({
        url: `/mobile/userLeave/getUserBasicInfo`,
        method: 'POST',
        data: params
    });
}

export function bannerList(params: AnyObject = {}) {
    return instance({
        url: `/mobile/index/bannerList`,
        method: 'POST',
        data: params
    });
}

//金刚区列表
export function getKingKongClientList(params: AnyObject = {}) {
    return instance({
        url: `/mobile/operate/king/kong/getKingKongClientList`,
        method: 'POST',
        data: params
    });
}

export function queryGuide(params: AnyObject = {}) {
    return instance({
        url: `/mobile/op/guide/queryGuide`,
        method: 'POST',
        data: params
    });
}

//旧版获取公开课列表接口
export function getAllCourseList(params: AnyObject = {}, currentPage: number) {
    return instance({
        url: `/mobile/course/getAllCourseList?currentPage=${currentPage}`,
        method: 'POST',
        data: params
    });
}

//新版获取公开课标签
export function getOpenCourseTags(params: AnyObject = {}) {
    return instance({
        url: `/mobile/opencourse/getOpenCourseTags`,
        method: 'POST',
        data: params
    });
}

//新版获取公开课列表
export function queryOpenCoursePage(params: AnyObject = {}) {
    return instance({
        url: `/mobile/opencourse/queryOpenCoursePage`,
        method: 'POST',
        data: params
    });
}


//分页查询活动商品列表
export function getActivityProductPage(params: AnyObject = {}) {
    return instance({
        url: process.env["VUE_APP_api2Domain"] + "/v1/course/c/ignore/product/getActivityProductPage",
        method: "POST",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: params,
    });
}


//查询商品详情
export function queryDetail(params: AnyObject = {}) {
    return instance({
        url: '/mobile/product/detail',
        method: 'POST',
        params
    });
}

//查询商品分类
export function queryCategory(params: AnyObject = {}) {
    return instance({
        url: '/mobile/product/category',
        method: 'POST',
        params
    });
}

//添加购物车
export function addWares(params: AnyObject = {}) {
    return instance({
        url: '/mobile/skuShopCart/add',
        method: 'POST',
        params
    });
}

//添加购物车
export function validCheckout(params: AnyObject = {}) {
    return instance({
        url: '/mobile/skuOrder/validCheckout',
        method: 'POST',
        params
    });
}

export function querySubject(params: AnyObject = {}) {
    return instance({
        url: '/mobile/subject/query',
        method: 'POST',
        data: params
    });
}

export function WeChatSignature(params: AnyObject = {}) {
    return instance({
        url: '/mobile/share/WeChatSignature',
        method: 'POST',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

// 获取服务器时间
export function getDate(params: AnyObject = {}) {
    return instance({
        url: "/mobile/common/getDate",
        method: "POST",
        params
    });
}


//获取所有专业科目
export function querySubjectList(params: AnyObject = {}) {
    return instance({
        url: "/mobile/subject/querySubjectList",
        method: "POST",
        params
    });
}

//获取用户最后选择的专业
export function getUserLastSelectSubject(params: AnyObject = {}) {
    return instance({
        url: "/mobile/research/mock/getUserLastSelectSubject",
        method: "POST",
        params
    });
}

//保存用户选择的专业/科目
export function saveSelectSubject(params: AnyObject = {}) {
    return instance({
        url: "/mobile/research/mock/saveSelectSubject",
        method: "POST",
        params
    });
}

export function getCommonJson(params: AnyObject = {}) {
    return instance({
        url: params.jsonUrl,
        method: 'get',
    });
}

export function trackPageinfo(params: AnyObject = {}) {
    return instance({
        url: '/track/api/pageinfo',
        method: 'POST',
        data: params
    })
}

export function getMiniProgramSubjects(params: AnyObject = {}) {
    return instance({
        url: '/v1/research/c/i/exam/getMiniProgramSubjects',
        baseURL: process.env["VUE_APP_api2Domain"],
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: params
    })
}


// AI对话-浮标显示校验（视频打点）
export function checkShowBuoyForVideo(params: AnyObject = {}) {
    return instance({
        url: process.env["VUE_APP_api2Domain"] + "/v1/robot/c/talk/checkShowBuoyForVideo",
        method: "POST",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: params
    });
}

// AI对话-浮标显示校验（题）
export function checkShowBuoyForQuestion(params: AnyObject = {}) {
    return instance({
        url: process.env["VUE_APP_api2Domain"] + "/v1/robot/c/talk/checkShowBuoyForQuestion",
        method: "POST",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: params
    });
}

//5.2 AI对话-权限校验
export function checkPermission(params: AnyObject = {}) {
    return instance({
        url: process.env["VUE_APP_api2Domain"] + "/v1/robot/c/talk/checkPermission",
        method: "POST",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: params
    });
}

//5.4.2 AI对话-发起AI对话，并更新来源（题库、模考、作业、错题本、笔记本、标记本）
export function talkWithAi(params: AnyObject = {}, type = 'question') {

    if (type === 'question') {
        return instance({
            url: process.env["VUE_APP_api2Domain"] + "/v1/robot/c/talk/talkWithAiForQuestion",
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: params
        });
    } else if (type === 'video') {
        return instance({
            url: process.env["VUE_APP_api2Domain"] + "/v1/robot/c/talk/talkWithAiForVideo",
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: params
        });
    } else {
        return instance({
            url: process.env["VUE_APP_api2Domain"] + "/v1/robot/c/talk/talkWithAiForSession",
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: params
        });
    }
}

export function getMessagePage(params: AnyObject = {}, type = 'question') {

    if (type === 'question') {
        return instance({
            url: process.env["VUE_APP_api2Domain"] + "/v1/robot/c/talk/getMessagePageForQuestion",
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: params
        });
    } else if (type === 'video') {
        return instance({
            url: process.env["VUE_APP_api2Domain"] + "/v1/robot/c/talk/getMessagePageForVideo",
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: params
        });
    } else {
        return instance({
            url: process.env["VUE_APP_api2Domain"] + "/v1/robot/c/talk/getMessagePageForSession",
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: params
        });
    }
}

//5.6 AI对话-查询单次对话（重试）
export function getMessageInfo(params: AnyObject = {}) {
    return instance({
        url: process.env["VUE_APP_api2Domain"] + "/v1/robot/c/talk/getMessageInfo",
        method: "POST",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: params
    });
}

//5.7.1 AI对话评价-查询所有标签
export function getAllLabelList(params: AnyObject = {}) {
    return instance({
        url: process.env["VUE_APP_api2Domain"] + "/v1/robot/c/talk/getAllLabelList",
        method: "POST",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: params
    });
}

//5.7.2 AI对话评价-保存评价
export function saveRating(params: AnyObject = {}) {
    return instance({
        url: process.env["VUE_APP_api2Domain"] + "/v1/robot/c/talk/saveRating",
        method: "POST",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: params
    });
}

//5.7.3 AI对话评价-历史评价详情
export function getRatingDetail(params: AnyObject = {}) {
    return instance({
        url: process.env["VUE_APP_api2Domain"] + "/v1/robot/c/talk/getRatingDetail",
        method: "POST",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: params
    });
}


