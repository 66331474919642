import axios, {AxiosRequestConfig} from 'axios';
import {Toast} from 'vant';
import signature from "@/utils/signature";

// create an axios instance
const instance = axios.create({
    // baseURL: import.meta.env.VITE_msierDomain,
    baseURL: process.env.VUE_APP_msierDomain,
    withCredentials: true,// send cookies when cross-domain requests
    timeout: 5000,
    headers: {
        "X-Requested-With": "XMLHttpRequest"
    }
});

// 请求拦截
instance.interceptors.request.use(
    (config: AnyObject = {}) => {
        if (config.url.includes('sieredu.com')) {
            config.baseURL = ''
        }
        if (config.url.includes('/track/api/pageinfo')) {
            config.baseURL = process.env.VUE_APP_trackDomain
        }
        // do something before request is sent
        if (sessionStorage.getItem('campToken')) {
            config.headers.Authorization = sessionStorage.getItem('campToken')
        }
        if (config.url.includes('static.sieredu.com')) {
            config.baseURL = '';
            config.withCredentials = false;
            delete config.headers;
        }
        return signature(config);
    },
    (error) => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

// 响应拦截
instance.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    (response) => {
        const res = response.data;
        if (res.code && res.code !== 0) {
            Toast(res.message || "请求状态异常");
        }
        return Promise.resolve(response.data);
    },
    (error) => {
        console.log('err' + error);
        const reg = new RegExp(/504|503|500|400/);
        if (reg.test(error.message) || error.message === 'Network Error') {
            Toast('请求错误');
        }
        return Promise.reject(error.message);
    }
);
/*export default function useAxiosApi(url: string, config: AxiosRequestConfig) {
  return useAxios(`${'/api'}${url}`,config,instance);
}*/
export default instance;
