import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vconsole from "vconsole";
import {
    ActionSheet,
    Cascader,
    Checkbox,
    Circle,
    DatetimePicker,
    Dialog,
    DropdownItem,
    DropdownMenu,
    Icon,
    List,
    Overlay,
    Picker,
    Popup,
    Rate,
    Slider,
    Stepper,
    Sticky,
    Swipe,
    SwipeItem,
    Tab,
    Tabs,
    Toast,
} from 'vant';
import 'vant/lib/index.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "./common/font/font.css";
import {getUrlParam} from "@/utils";

if (getUrlParam('sessionId')) {//判断为小程序内嵌页面
    window.temporarySessionId = getUrlParam('sessionId');
}

if (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'staging') {
    new Vconsole()
}

const app = createApp(App);

app.use(Swipe);
app.use(SwipeItem);
app.use(Overlay);
app.use(Picker);
app.use(Icon);
app.use(Rate);
app.use(Dialog);
app.use(Toast);
app.use(Checkbox);
app.use(Popup);
app.use(Tab);
app.use(Tabs);
app.use(List);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(Slider);
app.use(Circle);
app.use(Stepper)
app.use(ActionSheet);
app.use(ElementPlus)
app.use(Cascader);
app.use(DatetimePicker)
app.use(Sticky);

app.config.compilerOptions.isCustomElement = (tag) => {
    return tag.startsWith('wx-open-launch-weapp')
}
app.use(store).use(router).mount('#app');
