import Clipboard from 'clipboard'
import {getDate} from "@/api/common";

//当前浏览器信息
export function checkBrowser() {
    const ua = window.navigator.userAgent.toLocaleLowerCase();
    let browserType = null;
    let Sys = {} as any;
    let s;
    if (s = ua.match(/rv:([\d.]+)\) like gecko/)) {
        Sys.ie = s[1];
        browserType = "IE";
    } else if (s = ua.match(/msie ([\d.]+)/)) {
        Sys.ie = s[1];
        browserType = "IE";
    } else if (s = ua.match(/firefox\/([\d.]+)/)) {
        Sys.firefox = s[1];
        browserType = "火狐";
    } else if (s = ua.match(/ubrowser\/([\d.]+)/)) {
        Sys.uc = s[1];
        browserType = "UC";
    } else if (s = ua.match(/opera.([\d.]+)/)) {
        Sys.opera = s[1];
        browserType = "欧朋";
    } else if (s = ua.match(/bidubrowser\/([\d.]+)/)) {
        Sys.baidu = s[1];
        browserType = "百度";
    } else if (s = ua.match(/metasr/)) {
        Sys.sougou = s[1];
        browserType = "搜狗";
    } else if (s = ua.match(/tencenttraveler\/([\d.]+)/)) {
        Sys.qq = s[1];
        browserType = "QQ";
    } else if (s = ua.match(/qqbrowse\/([\d.]+)/)) {
        Sys.qq = s[1];
        browserType = "QQ";
    } else if (s = ua.match(/qqbrowser\/([\d.]+)/)) {
        Sys.qq = s[1];
        browserType = "QQ";
    } else if (s = ua.match(/maxthon\/([\d.]+)/)) {
        Sys.aoyou = s[1];
        browserType = "遨游";
    } else if (s = ua.match(/microMessenger\/([\d.]+)/)) {
        Sys.weixin = s[1];
        browserType = "微信";
    } else if (s = ua.match(/edge\/([\d.]+)/)) {
        Sys.edge = s[1];
        browserType = "Edge";
    } else if (s = ua.match(/edg\/([\d.]+)/)) {
        Sys.edge = s[1];
        browserType = "Edge";
    } else if (s = ua.match(/360se/)) {
        Sys.is360 = s[1];
        browserType = "360安全";
    } else if (s = ua.match(/360ee/)) {
        Sys.is360 = s[1];
        browserType = "360极速";
    } else if (s = ua.match(/chrome\/([\d.]+)/)) {
        var is360 = _mime("type", "application/vnd.chromium.remoting-viewer");

        if (is360) {
            Sys.is360 = s[1];
            browserType = "360";
        } else {
            Sys.Chrome = s[1];
            browserType = "谷歌";
        }

    } else if (s = ua.match(/version\/([\d.]+).*safari/)) {
        Sys.safari = s[1];
        browserType = "Safari";
    } else {
        return {
            browser: "other",
            browserVersion: ""
        };
    }
    return {
        browser: browserType,
        browserVersion: Sys[Object.keys(Sys)[0]]
    };
}

export function _mime(option: any, value: any) {
    var mimeTypes = window.navigator.mimeTypes as any;
    for (var mt in mimeTypes) {
        if (mimeTypes[mt][option] == value || mimeTypes[mt][option] == "application/360softmgrplugin") {
            return true;
        }
    }
    return false;
}

export function toast(msg: string, duration: number) {
    console.log('toast',msg);
    let elements = document.getElementsByClassName("utilsToast");
    while (elements.length > 0) {
        elements[0].classList.remove("utilsToast");
    }
    duration = isNaN(duration) ? 3000 : duration;
    var m = document.createElement("div");
    m.innerHTML = msg;
    m.className = "utilsToast";
    document.body.appendChild(m);
    setTimeout(function () {
        var d = 0.5;
        setTimeout(function () {
            document.body.removeChild(m);
        }, d * 1000);
    }, duration);
}


export function deepClone(source: any) {
    if (!source && typeof source !== 'object') {
        // throw new Error('error arguments' as string,'deepClone' as string)
    }
    const targetObj = source.constructor === Array ? [] : {}
    Object.keys(source).forEach(keys => {
        if (source[keys] && typeof source[keys] === 'object') {
            targetObj[keys] = deepClone(source[keys])
        } else {
            targetObj[keys] = source[keys]
        }
    })
    return targetObj
}

// 格式dateFormat(date,"你想要的格式") 如"YYYY-MM-DD hh:mm:ss" "YY年M-D hh-mmss 周w"  "周"可以是任意字 W是汉字 w是数字
export function dateFormat(date: any, format: any) {
    if ((date + "").length < 13) {
        date = date * 1000;
    }
    if (typeof date === "string") {
        let mts = date.match(/(\/Date\((\d+)\)\/)/);
        if (mts && mts.length >= 3) {
            date = parseInt(mts[2]);
        }
    }
    date = new Date(date);
    if (!date || date.toUTCString() == "Invalid Date") {
        return "";
    }
    let WeeKday = ["日", "一", "二", "三", "四", "五", "六"];
    let weekday = ["7", "1", "2", "3", "4", "5", "6"];
    let map: Record<any, any> = {
        Y: date.getFullYear() + "", //年
        M: date.getMonth() + 1, //月份
        D: date.getDate(), //日
        h: date.getHours(), //小时
        m: date.getMinutes(), //分
        s: date.getSeconds(), //秒
        q: Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
        W: date.getDay(), //星期汉
        w: date.getDay() //星期数字
    };
    format = format.replace(/([YMDdhmsqSWw])+/g, function (allT: any, t: any) {
        let v = map[t];
        if (v !== undefined) {
            if (t === "y" || t === "Y") {
                v = v.substr(4 - allT.length);
            }
            if (t != "y" && t != "Y" && t != "w") {
                if (allT.length > 1) {
                    v = v < 10 ? "0" + v : v + "";
                    v = v.substr(allT.length - 2);
                }
            }
            if (t === "W") {
                v = WeeKday[v];
            } else if (t === "w") {
                v = weekday[v];
            }
            return v;
        }
        return allT;
    });
    return format;
}

//复制到粘贴板
export function copyText(text: string, e: any) {
    return new Promise((resolve, reject) => {
        const clipboard = new Clipboard(e.target, {
            text: () => text
        })
        clipboard.on('success', () => {
            //复制成功
            resolve('success');
            // 释放内存
            clipboard.destroy()
        })
        clipboard.on('error', () => {
            // 不支持复制
            reject('error');
            // 释放内存
            clipboard.destroy()
        })
        // 解决第一次点击不生效的问题，如果没有，第一次点击会不生效
        // clipboard.onClick(e)
    })

}

//获取当前网速
export function networkSpeed() {
    return new Promise((resolve, reject) => {
        let startTime = Date.now();
        let xhr = new XMLHttpRequest();
        xhr.open("GET", "https://static.sieredu.com/front/newWeb/common/img/logo.png");
        let speed = 0;
        xhr.onload = function () {
            let duration = (Date.now() - startTime) / 1000;
            let size = xhr.getResponseHeader("Content-Length"); //  图片大小5M
            speed = (Number(size) / duration);
            resolve(speed);
        };
        xhr.send();
    });
}

export function getDateTime(type: boolean) {
    return new Promise((resolve, reject) => {
        getDate().then(res => {
            if (type) {
                resolve(res.entity);
            } else {
                let num = parseInt(String(res.entity / 1000))
                resolve(num);
            }
        });
    });

}

//获取cookie
export function getCookie(c_name: any) {
    if (document.cookie.length > 0) {
        const match = document.cookie.match(new RegExp('(^| )' + c_name + '=([^;]+)'));
        if (match) {
            return match[2];
        }
        return "";
    }
    return "";
}

//设置cookie
export function setCookie(c_name: any, value: any, expiredays: any) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    document.cookie =
        c_name +
        "=" +
        escape(value) +
        ";domain=.sieredu.com;path=/" + //设置cookie主域为sieredu.com,实现子域之间共享
        (expiredays == null ? "" : ";expires=" + exdate.toUTCString());
}

//清除cookie
export function clearCookie(c_name: any) {
    setCookie(c_name, "", -1);
}

export function decryptByDES(ciphertext: any, key: any) {
    let CryptoJS = window.CryptoJS;
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const decrypted = CryptoJS.TripleDES.decrypt({
        ciphertext: CryptoJS.enc.Base64.parse(ciphertext) // base64 编码解密
    }, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
}

export function sierEmojiToImg(str: any) {
    str = str.replace(/\n/g, '<br/>');
    window.sierEmojiData.forEach((item: any) => {
        if (str.indexOf(item) > -1) {
            let emojiName: string = item.replace(/\[|\]/g, '');
            let imageName: any = emojiName + '@2x.png';
            let url: any = `${process.env.VUE_APP_staticCommonDomain}images/sierEmoji/${imageName}`;
            let img: any = '<img style="width: 20px;height: 20px;vertical-align:sub" src="' + url + '"/>'
            str = str.replaceAll(item, img);
        }
    });

    return str;
}

export function getDeviceType() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipod|ipad/.test(userAgent)) {
        return '手机';
    } else if (/ipad/.test(userAgent)) {
        return '平板';
    } else if (/android/.test(userAgent)) {
        if (/mobile/.test(userAgent)) {
            return '手机';
        } else {
            return '平板';
        }
    } else if (/harmony/.test(userAgent)) {
        if (/mobile/.test(userAgent)) {
            return '手机';
        } else {
            return '平板';
        }
    } else if (/macintosh/.test(userAgent)) {
        return 'PC';
    } else if (/windows/.test(userAgent)) {
        return 'PC';
    } else {
        return '未知设备';
    }
}
export function getSystemType() {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.indexOf('windows')!== -1) {
        return 'Windows';
    } else if (userAgent.indexOf('linux')!== -1) {
        return 'Linux';
    } else if (userAgent.indexOf('macintosh')!== -1) {
        return 'MacOS';
    } else if (userAgent.indexOf('android')!== -1) {
        return 'Android';
    } else if (userAgent.indexOf('iphone')!== -1 || userAgent.indexOf('ipad')!== -1 || userAgent.indexOf('ipod')!== -1) {
        return 'iOS';
    } else if (userAgent.indexOf('harmony')!== -1) {
        return 'HarmonyOs';
    } else {
        return '未知';
    }
}
//获取URL参数
export function getUrlParam(name:string) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    var r = window.location.search.substr(1).match(reg); //匹配目标参数
    if (r != null) {
        return decodeURI(r[2]);
    } else {
        return null;
    }
}
