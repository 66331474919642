import {getCookie, getDeviceType, getSystemType, getUrlParam} from "../utils";
import Qs from "qs";

export default config => {
    if (config.headers && !config.headers.Accept) {//可防止dev环境请求头没有Accept
        config.headers.Accept = "application/json, text/plain, */*";
    }

    if (config.method == 'POST' || config.method == 'post') {
        if (window && window != undefined) {
            let hexRandom = '';
            while (hexRandom.length < 32) {
                // 生成一个随机的 16 进制字符串，长度为 8
                const randomPart = Math.floor(Math.random() * 0x100000000).toString(16);
                hexRandom += randomPart.padStart(8, '0');
            }
            // 截取前 32 位
            const uuid = hexRandom.slice(0, 32);
            config.headers.uuid = uuid;
        }
    }


    if (config.url.includes('api2.sieredu.com') || (config.baseURL && config.baseURL.includes('api2.sieredu.com'))) {

        if (window && window != undefined) {
            const crypto = window.CryptoJS;
            if (!crypto) {
                return config;
            }

            if(window.temporarySessionId) {//判断为小程序内嵌页面
                config.headers.authorization = `Bearer ${window.temporarySessionId}`;
                config.headers['x-app-type'] = '31';
            }else {
                config.headers.authorization =getCookie('msid')? `Bearer ${getCookie('msid')}`:'';
                config.headers['x-app-type'] = '1';
            }
            let deviceType = getDeviceType();
            let osType = getSystemType().toLowerCase();
            config.headers['x-device-type'] = deviceType == 'PC' ? '1' : deviceType == '手机' ? '2' : deviceType == '平板' ? '3' : '10';
            config.headers['x-os-type'] = osType.includes('win') ? '1' : osType.includes('mac') ? '2' : osType.includes('ios') ? '3' : osType.includes('android') ? '4' : osType.includes('harmony') ? '5' : osType.includes('linux') ? '6' : '10';
            config.headers['x-platform'] = '4';

            // 配置信息
            const appKey = "10000";  // 替换为下发的appKey
            const appSecret = "e1018d3bb5664bada75ef6a619a07900";  // 替换为下发的secret
            //需要加密的请求头
            let headersToSign = ['x-ca-key', 'x-ca-nonce', 'x-ca-signature-method', 'x-ca-timestamp', "authorization", "x-device-type", "x-os-type", "x-app-type", "x-platform", "source-version", "app-version"];

            // 获取当前时间戳
            const timestamp = new Date().getTime();
            // 生成随机nonce
            const nonce = crypto.lib.WordArray.random(16).toString();

            // 设置必要的请求头
            config.headers['x-ca-timestamp'] = timestamp.toString();
            config.headers['x-ca-nonce'] = nonce;
            config.headers['x-ca-key'] = appKey;
            config.headers['x-ca-signature-method'] = 'HmacSHA256';
            config.headers['x-ca-signature-headers'] = headersToSign.join(',');


            // 获取请求方法
            const method = config.method.toUpperCase();

            // 获取Accept头
            let accept = config.headers['Accept'] || '';

            // 获取Content-Type头
            let contentType = config.headers['Content-Type'] || '';

            // 获取Date头
            let date = config.headers['Date'] || '';

            // 处理body内容的MD5
            let contentMD5 = '';
            if (config.data) {
                let body = config.data;
                if (contentType.includes('application/x-www-form-urlencoded')) {
                    // 对于form数据，需要排序处理
                    body = body.split('&')
                        .sort()
                        .join('&');
                }
                if (contentType.includes('application/json')) {
                    // 对于form数据，需要排序处理
                    body = JSON.stringify(body);
                }
                contentMD5 = crypto.MD5(body).toString(crypto.enc.Base64);
                config.headers['content-md5'] = contentMD5;
            }

            // 处理Headers
            let headerString = headersToSign.sort().map(headerKey => {
                return `${headerKey.toLowerCase()}:${config.headers[headerKey.toLowerCase()] || ''}`;
            }).join('\n');
            // 处理URL参数
            let url = config.url.includes('sieredu.com') ? config.url.substring(config.url.indexOf('sieredu.com') + 11) : config.url;
            let params = [];

            if (contentType.includes('x-www-form-urlencoded')) {
                let data = Qs.parse(config.data);
                // 添加Query参数
                for (const key in data) {
                    params.push(`${key}=${data[key]}`);
                }
            }

            // 排序并组合参数
            if (params.length > 0) {
                params.sort();
                url += '?' + params.join('&');
            }

            // 组装签名字符串
            let stringToSign = [
                method,
                accept,
                contentMD5,
                contentType,
                date,
                headerString,
                url
            ];
            stringToSign = stringToSign.join('\n');

            console.log(stringToSign,'333333333333333333333333333333333333333')
            // 生成签名
            const signature = crypto.enc.Base64.stringify(
                crypto.HmacSHA256(stringToSign, appSecret)
            );

            // 设置签名相关的请求头
            config.headers['x-ca-signature'] = signature;
        }

    }
    return config;
}
