import {WeChatSignature} from '@/api/common.ts'

export default {
  /* eslint-disable */
  /**
   * params中参数有Channel、Context，如果有Channel，则需要传参给登录页，Context是有Channel才会有Context
   * @param params
   * 入股哦呦Channel，Channel必传，Context如若没有特殊的参数，可不传
   */
  goLogin(params = {}) {
    const msierDomain = process.env.VUE_APP_msierDomain;
    if (params && params.channel) {
      let context = {
        "properties": {
          "feature": params.channel,
          "reachSource": this.getUrlParam('reachSource') ?
              this.getUrlParam('reachSource') :
              null,
        },
      }
      const extParams = {
        channel: params.channel,
        context: params.context || context,
      };
      this.appHandlers.jumpUrl({
        url: 'user/login',
        returnUrl: window.location.href,
        extParams,
      }, {
        url: `${msierDomain}/mobile/user/login`,
        returnUrl: window.location.href,
        extParams,
      });
    } else {
      this.appHandlers.jumpUrl({
        url: 'user/login',
        returnUrl: window.location.href,
      }, {
        url: `${msierDomain}/mobile/user/login`,
        returnUrl: window.location.href,
      });
    }

  },
  appHandlers: {
    postMessageTerminal(eventName) {
      let reg = new RegExp("(^|&)" + 'harmonyInfo' + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
      let r = window.location.search.substr(1).match(reg); //匹配目标参数

      if (window.webkit && window.webkit.messageHandlers &&
          window.webkit.messageHandlers[eventName] &&
          window.webkit.messageHandlers[eventName].postMessage) {
        // 调用IOS方法
        return 1;
      } else if (window.android && window.android &&
          window.android[eventName]) {
        // 调用android方法
        return 2;
      } else if (!!(window.harmonyInfo || r != null && unescape(r[2]))) {
        // 调用鸿蒙方法
        return 3;
      } else {
        return 0;
      }
    }
    ,
    callHandlers(eventName, params, otherEvent) {
      console.log('--------- 调用postMessageTerminal,寻找方法：' + eventName);
      if (this.postMessageTerminal(eventName) == 1) {
        console.log('---------调用IOS方法：' + eventName)
        window.webkit.messageHandlers[eventName].postMessage(params || {})
      } else if (this.postMessageTerminal(eventName) == 2) {
        console.log('---------调用android方法：' + eventName)
        if (params) {
          window.android[eventName](JSON.stringify(params));
        } else {
          window.android[eventName]();
        }
      } else if (this.postMessageTerminal(eventName) == 3) {
        console.log('---------调用鸿蒙方法：' + eventName)
        if (params) {
          // window.harmonyInfo
          window.harmonyMsgHandler[eventName](JSON.stringify(params));
        } else {
          window.harmonyMsgHandler[eventName]();
        }
      } else {
        console.log('---------没有找到方法');
        otherEvent && otherEvent();
      }
    }
    ,
    jumpUrl(params = {}, h5Params = {}) {
      let that = this;
      console.log('调用appJump', 'app params:' + JSON.stringify(params),
          'h5 params:' + JSON.stringify(h5Params));
      this.callHandlers('appJump', params, () => {
        console.log(params);
        if (h5Params.url) {
          let href = h5Params.url;
          let returnUrlStr = '';
          let extParams = '';
          if (h5Params.returnUrl) {
            returnUrlStr = 'returnUrl=' +
                encodeURIComponent(h5Params.returnUrl);
          }
          if (h5Params.extParams) {
            extParams = "&";
            //循环extParams对象
            for (let key in h5Params.extParams) {
              //如果key是对象类型encodeURIComponent
              if (typeof h5Params.extParams[key] == 'object') {
                h5Params.extParams[key] = encodeURIComponent(
                    JSON.stringify(h5Params.extParams[key]));
              }
              extParams += key + '=' + h5Params.extParams[key] + '&';
            }
          }
          window.location.href = `${href}?${returnUrlStr}${extParams}`;

        }
      });
    }
    ,
    closeWebview() {
      this.callHandlers('closeWebview');
    }
    ,
    userCancelSccount() {
      this.callHandlers('userCancelSccount');
    }
    ,
    callHardware(status) {
      this.callHandlers('callHardware_forApp', status);
    }
    ,
    getDataFromWebView(params) {
      this.callHandlers('getDataFromWebView', params);
    }
    ,
    hiddenHead(status, otherEvent) {
      let params = {
        status,
      };
      this.callHandlers('hiddenHead', params, () => {
        otherEvent && otherEvent();
      });
    },
  },
  // 生成随机字符串
  randomString(len) {
    len = len || 12;
    var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    var maxPos = $chars.length;
    var pwd = '';
    for (let i = 0; i < len; i++) {
      pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
  },
  //是否在app中
  isApp() {
    if (this.isiOSApp()) {
      return 1;
    } else if (this.isAndroidApp()) {
      return 2;
    } else if (this.isHarmonyApp()) {
      return 3;
    } else {
      return 0;
    }
  },
  //是否在iosApp中
  isiOSApp() {
    return !!(window.iOSInfo || this.getUrlParam('iOSInfo'));
  },
  //是否在androidApp中
  isAndroidApp() {
    return !!(window.android || this.getUrlParam('AndroidInfo'));
  },
  //是否在鸿蒙App中
  isHarmonyApp() {
    return !!(window.harmonyInfo ||this.getUrlParam('harmonyInfo'));
  },
  // 是否是wap
  isMobile: function() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    var huawei = sUserAgent.match(/mobile huaweibrowser/i) ==
        "mobile huaweibrowser";
    var Mobile = sUserAgent.match(/Mobile/i) == "Mobile";
    var Phone = sUserAgent.match(/Phone/i) == "Phone";
    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid ||
        bIsCE || bIsWM || huawei || Mobile || Phone) {
      return true;
    } else {
      return false;
    }
  },
  //判断浏览器版本；
  getBroswer: () => {
    let Sys = {};
    let ua = navigator.userAgent.toLowerCase();
    let s;
    (s = ua.match(/edge\/([\d.]+)/)) ?
        (Sys.edge = s[1]) :
        (s = ua.match(/rv:([\d.]+)\) like gecko/)) ?
            (Sys.ie = s[1]) :
            (s = ua.match(/msie ([\d.]+)/)) ?
                (Sys.ie = s[1]) :
                (s = ua.match(/firefox\/([\d.]+)/)) ?
                    (Sys.firefox = s[1]) :
                    (s = ua.match(/chrome\/([\d.]+)/)) ?
                        (Sys.chrome = s[1]) :
                        (s = ua.match(/opera.([\d.]+)/)) ?
                            (Sys.opera = s[1]) :
                            (s = ua.match(/version\/([\d.]+).*safari/)) ?
                                (Sys.safari = s[1]) :
                                0;
    if (Sys.edge) return {broswer: "Edge", version: Sys.edge};
    if (Sys.ie) return {broswer: "IE", version: Sys.ie};
    if (Sys.firefox) return {broswer: "Firefox", version: Sys.firefox};
    if (Sys.chrome) return {broswer: "Chrome", version: Sys.chrome};
    if (Sys.opera) return {broswer: "Opera", version: Sys.opera};
    if (Sys.safari) return {broswer: "Safari", version: Sys.safari};
    return {broswer: "", version: "0"};
  },
  iosAndroid: () => {
    //1：安卓-----2：ios----0:其他
    var p = 0;
    var u = navigator.userAgent,
        app = navigator.appVersion;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
      p = 1;
    }
    if (isIOS) {
      p = 2;
    }
    return p;
  },
  //获取URL参数
  getUrlParam: (name) => {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    let r = window.location.search.substr(1).match(reg); //匹配目标参数
    if (r != null) {
      return unescape(r[2]);
    } else {
      return null;
    }
  },
  //本地数据存储
  setLocalCookie: function(k, v, t, domain) {
    typeof window.localStorage !== "undefined" ? localStorage.setItem(k, v) :
        (function() {
          t = t || 365 * 12 * 60 * 60;
          domain = domain ? domain : ".sieredu.com";
          document.cookie = k + "=" + v + ";max-age=" + t + ";domain=" +
              domain + ";path=/";
        })()
  },
  //本地数据获取
  getLocalCookie: function(k) {
    k = k || "localDataTemp";
    return typeof window.localStorage !== "undefined" ?
        localStorage.getItem(k) :
        (function() {
          var all = document.cookie.split(";");
          var cookieData = {};
          for (var i = 0, l = all.length; i < l; i++) {
            var p = all[i].indexOf("=");
            var dataName = all[i].substring(0, p).
                replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
            cookieData[dataName] = all[i].substring(p + 1);
          }
          return cookieData[k]
        })();
  },
  //删除本地数据
  clearLocalData: function(k) {
    k = k || "localDataTemp";
    typeof window.localStorage !== "undefined" ? localStorage.removeItem(k) :
        (function() {
          document.cookie = k + "=temp" + ";max-age=0";
        })()
  },
  //获取cookie
  getCookie: function(c_name) {
    if (document.cookie.length > 0) {
      let c_start = document.cookie.indexOf(c_name + "=");
      if (c_start != -1) {
        c_start = c_start + c_name.length + 1;
        let c_end = document.cookie.indexOf(";", c_start);
        if (c_end == -1) c_end = document.cookie.length;
        return unescape(document.cookie.substring(c_start, c_end));
      }
    }
    return "";
  },
  //设置cookie
  setCookie: function(c_name, value, expireTime) {
    var exdate = new Date();
    exdate.setDate(exdate.getTime() + expireTime * 60 * 60 * 1000);
    document.cookie =
        c_name +
        "=" +
        escape(value) +
        ";domain=.sieredu.com;path=/" + //设置cookie主域为sieredu.com,实现子域之间共享
        (expireTime == null ? "" : ";expires=" + exdate.toGMTString());
  },
  //清除cookie
  clearCookie: function(c_name) {
    this.setCookie(c_name, "", -1);
  },
  //控制触发次数
  debounce: function(func, wait, immediate) {
    var timeout, result;
    return function() {
      var context = this,
          args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) result = func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) result = func.apply(context, args);
      return result;
    };
  },
  //滚动(true滚动 / false禁止)
  scrollFlag: bool => {
    if (bool) {
      document.body.style.height = "unset";
      document.body.style["overflow-y"] = "auto";
    } else {
      document.body.style.height = "100vh";
      document.body.style["overflow-y"] = "hidden";
    }
  },
  //toast 提示
  toast(msg, duration) {
    duration = isNaN(duration) ? 3000 : duration;
    var m = document.createElement('div');
    m.innerHTML = msg;
    m.style.cssText = "max-width:60%;min-width: 150px;padding:0 14px;height: 40px;color: rgb(255, 255, 255);line-height: 40px;text-align: center;border-radius: 4px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);z-index: 999999;background: rgba(0, 0, 0,.7);font-size: 16px;";
    document.body.appendChild(m);
    setTimeout(function() {
      var d = 0.5;
      m.style.webkitTransition = '-webkit-transform ' + d +
          's ease-in, opacity ' + d + 's ease-in';
      m.style.opacity = '0';
      setTimeout(function() {
        document.body.removeChild(m)
      }, d * 1000);
    }, duration);

  },

  // 格式dateFormat(date,"你想要的格式") 如"YYYY-MM-DD hh:mm:ss" "YY年M-D hh-mmss 周w"  "周"可以是任意字 W是汉字 w是数字
  dateFormat: (date, format) => {
    if (typeof date === "string") {
      let mts = date.match(/(\/Date\((\d+)\)\/)/);
      if (mts && mts.length >= 3) {
        date = parseInt(mts[2]);
      }
    }
    date = new Date(date);
    if (!date || date.toUTCString() == "Invalid Date") {
      return "";
    }
    let WeeKday = ["日", "一", "二", "三", "四", "五", "六"];
    let weekday = ["7", "1", "2", "3", "4", "5", "6"];
    let map = {
      Y: date.getFullYear() + "", //年
      M: date.getMonth() + 1, //月份
      D: date.getDate(), //日
      h: date.getHours(), //小时
      m: date.getMinutes(), //分
      s: date.getSeconds(), //秒
      q: Math.floor((date.getMonth() + 3) / 3), //季度
      S: date.getMilliseconds(), //毫秒
      W: date.getDay(), //星期汉
      w: date.getDay(), //星期数字
    };
    format = format.replace(/([YMDdhmsqSWw])+/g, function(allT, t) {
      let v = map[t];
      if (v !== undefined) {
        if (t === "y" || t === "Y") {
          v = v.substr(4 - allT.length);
        }
        if (t != "y" && t != "Y" && t != "w") {
          if (allT.length > 1) {
            v = v < 10 ? "0" + v : v + "";
            v = v.substr(allT.length - 2);
          }
        }
        if (t === "W") {
          v = WeeKday[v];
        } else if (t === "w") {
          v = weekday[v];
        }
        return v;
      }
      return allT;
    });
    return format;
  },
  //价格最多保留2位小数,不默认添加小数
  priceFixed: f => {
    f = Math.round(f * 100) / 100;
    return f;
  },
  //字符串是否为空
  isEmpty(obj) {
    if (obj === undefined) {
      return true;
    } else if (obj == null) {
      return true;
    } else if (typeof obj === "string") {
      if (this.trim(obj) == "") {
        return true;
      }
    }
    return false;
  },
  //去掉首尾空格
  trim(str) {
    if (typeof str !== "string") {
      return str;
    }
    if (typeof str.trim === "function") {
      return str.trim();
    } else {
      return str.replace(/^(\u3000|\s|\t|\u00A0)*|(\u3000|\s|\t|\u00A0)*$/g,
          "");
    }
  },
  //邮箱校验
  emailCheck(value) {
    let state = 1; //1、验证通过   2、数据为空   3、验证失败
    if (this.isEmpty(value)) {
      state = 2;
    } else if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
        value)) {
      state = 3;
    }
    return state;
  },
  //名字校验  2~15个字符
  nameCheck(value) {
    value = value.replace(/\s+/g, ""); // 去除空格
    let state = 1; //1、验证通过   2、数据为空   3、验证失败
    if (this.isEmpty(value)) {
      state = 2;
    } else if (value.length == 1 || value.length > 15) {
      state = 3;
    }
    return state;
  },
  //手机号校验
  mobileCheck(value) {
    let state = 1; //1、验证通过   2、数据为空   3、验证失败
    if (this.isEmpty(value)) {
      state = 2;
    } else if (!/^1\d{10}$/.test(value)) {
      state = 3;
    }
    return state;
  },
  //内容框校验
  contentCheck(value) {
    let state = 1; //1、验证通过   2、数据为空   3、验证失败
    if (this.isEmpty(value)) {
      state = 2;
    }
    return state;
  },
  // 是否是微信浏览器
  weixnWap: function() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      return true;
    } else {
      return false;
    }
  },
  //验证qq号码
  qqCheck(value) {
    let state = 1; //1、验证通过   2、数据为空   3、验证失败
    if (this.isEmpty(value)) {
      state = 2;
    } else if (!/^[0-9]*$/.test(value)) {
      state = 3;
    }
    return state;
  },
  zcInit() {
    if (document.getElementById("zhichiScript")) {
      console.log('存在了')
    } else {
      (function(w, d, e, x) {
        w[e] = function() {
          w.cbk = w.cbk || [];
          w.cbk.push(arguments);
        };
        x = d.createElement("script");
        x.async = true;
        x.id = "zhichiScript";
        x.className = "zhiCustomBtn"; //该class绑定到自定义按钮上 第一步
        x.src = "https://sieredu.sobot.com/chat/frame/v2/entrance.js?sysnum=9f41a47c28c146ad9fb664e957fc3b7f";
        d.body.appendChild(x);
      })(window, document, "zc");
      zc("config", {
        custom: true, //设置自定义生效 第二步
        man_trace: "true", //开启收集用户方访问轨迹 默认不收集 也可在后台直接开启
        // anchor: "false", //新窗口打开咨询页
        // top_bar_flag: 1, //是否显示聊天页面顶部返回栏 仅H5 1开启 0关闭
        title_flag: 3, //显示当前客服昵称。
        color: "00c3b8",
        height: "600",
        width: "540",
      });
    }
  },
  //把后台返回的时间字符串转换成时间戳
  dataTime(e) {
    var date = e;
    date = new Date(Date.parse(date.replace(/-/g, "/")));
    date = date.getTime();
    return date;
  },
  addJs(urlm, name, {flag = true} = {}, callback) {
    (function(w, d, e, x) {
      console.log(urlm)
      w[e] = function() {
        w.cbk = w.cbk || [];
        w.cbk.push(arguments);
      };
      x = d.createElement("script");
      x.async = flag;
      x.src = urlm;
      d.body.appendChild(x);
      if (callback) {
        x.onload = function() {
          callback();
        }
      }
    })(window, document, name);
  },
  zcFun() {
    (function(w, d, e, x) {
      w[e] = function() {
        w.cbk = w.cbk || [];
        w.cbk.push(arguments);
      };
      x = d.createElement("script");
      x.async = true;
      x.id = "zhichiScript";
      x.className = "zhiCustomBtn"; //该class绑定到自定义按钮上 第一步
      x.src = "https://sieredu.sobot.com/chat/frame/v2/entrance.js?sysnum=9f41a47c28c146ad9fb664e957fc3b7f";
      d.body.appendChild(x);
    })(window, document, "zc");
    zc("config", {
      custom: true, //设置自定义生效 第二步
      man_trace: "true", //开启收集用户方访问轨迹 默认不收集 也可在后台直接开启
      // anchor:"true",//新窗口打开咨询页
      top_bar_flag: 1, //是否显示聊天页面顶部返回栏 仅H5 1开启 0关闭
      title_flag: 3, //显示当前客服昵称。
      color: "00c3b8",
      height: "600",
      width: "540",
    });
  },
  tanglangJs(code) {
    this.addJs("https://probe.bjmantis.net/chat/jquery-1.12.4.min.js",
        "tlJs");
    if (process.env.BUILD_TYPE === 'pro' || process.env.BUILD_TYPE ===
        'pre') {
      let newCode = '60c1f9b7e7d78a0d20ad4cb6';
      if (code) {
        newCode = code;
      }
      this.addJs(
          "https://pg-talk2.bjmantis.net/chat/js/dist/mantis.min.js?6826#" +
          newCode, "tl");
    } else {
      this.addJs(
          "https://talk2.bjmantis.net/chat/js/dist/mantis.min.js?5191#61bc3ad5a246964b3618860e",
          "tl");
    }
  },
  weixinShare: function(data) {
    let logo = `${process.env.VUE_APP_staticCommonDomain}images/logoTitIcon.jpg`
    let shareData = {
      title: "斯尔教育",
      desc: "成就每一个渴望更加专业的梦想",
      url: window.location.href,
      img: "https:" + process.env.VUE_APP_msierDomain + logo,
    }
    if (data) {
      shareData = data;
    }
    if (this.getUrlParam("share") != 1 && this.getUrlParam("shareKey") ==
        null) {
      if (shareData.url.indexOf("?") >= 0) {
        shareData.url = shareData.url + "&share=1";
      } else {
        shareData.url = shareData.url + "?share=1";
      }
    }
    let params = {
      "url": window.location.href,
    }
    WeChatSignature(params).then(res => {
      if (res.code == 0) {
        let entity = res.entity;
        // 配置
        wx.config({
          debug: false,
          appId: entity.appId,
          timestamp: entity.timestamp,
          nonceStr: entity.nonceStr,
          signature: entity.signature,
          jsApiList: [
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'onMenuShareTimeline',
            'onMenuShareAppMessage',
          ],
        });
        let share_config = {
          title: shareData.title, // 分享标题
          desc: shareData.desc, // 分享描述
          link: shareData.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: shareData.img, // 分享图标
          success: function() {
          },
          fail: function(res) {
          },
        };
        wx.ready(function() {
          wx.onMenuShareAppMessage(share_config);
          wx.updateAppMessageShareData(share_config);
          //“分享到朋友圈”按钮的分享内容
          wx.onMenuShareTimeline(share_config);
          wx.updateTimelineShareData(share_config);
          wx.error(function(res) {
            // alert(res+'error');
          });
        });
      } else {
        console.log('微信暂时无法进行分享');
      }
    })
  },

  //添加好友(未完成 后续调研补充此方法 切勿使用)
  // weixinAdd: function (data) {
  //     let params = {
  //         "url": data
  //     };
  //     WeChatSignature(params).then(res => {
  //         if (res.code == 0) {
  //             let entity = res.entity;
  //             // 配置JS-SDK
  //             wx.config({
  //                 debug: false,
  //                 appId: entity.appId,
  //                 timestamp: entity.timestamp,
  //                 nonceStr: entity.nonceStr,
  //                 signature: entity.signature,
  //                 jsApiList: ['openEnterpriseWechatProfile']
  //             });
  //             let add_config = {
  //                 corpId: "", //
  //                 userId: "", //
  //                 success: function () {
  //                 },
  //                 fail: function (res) {
  //                 }
  //             };
  //             //传递一些参数，如企业微信的corpId、userId
  //             // 使用wx.openEnterpriseWechatProfile方法来唤起微信并添加企业微信好友
  //             wx.ready(function () {
  //                 wx.openEnterpriseWechatProfile(add_config);
  //                 // 错误处理
  //                 wx.error(function (res) {
  //
  //                 });
  //             });
  //         } else {
  //             // 错误处理
  //             console.log('失败');
  //         }
  //     })
  // },

  goFun: function(gourl) {
    let clickFlag = false;
    let timer;
    let appDownUrl = ""; //app下载地址
    let iosAndroid = this.iosAndroid();
    let appGoUrl = gourl;
    if (gourl.indexOf("sieredu://") < 0) {
      appGoUrl = 'sieredu://' + gourl;
    }
    if (iosAndroid == 1) {
      //创建iframe
      let ifr = document.createElement("iframe");
      //iframe设置为app的协议打开方式地址
      ifr.src = appGoUrl;//安卓协议启动地址
      ifr.style.display = "none";//隐藏
      let t = Date.now();//取当前时间戳
      //延迟执行iframe 实现访问app协议打开app
      setTimeout(function() {
        document.body.appendChild(ifr);
      }, 96);
      //延迟执行 如果1s没响应，就表示你手机中没有该app。就可以去下载了
      setTimeout(function() {
        if (Date.now() - t < 1200) {
          clickFlag = true;
          document.body.appendChild(ifr);
          let msierDomain = process.env.VUE_APP_msierDomain;
          let url = `${msierDomain}/mobile/app/download`
          if (appGoUrl) {
            url = url + '?appurl=' + appGoUrl
          }
          window.location.href = url;
        }
      }, 1001);
    } else if (iosAndroid == 2) {
      let t = Date.now();//取当前时间戳
      //延迟直接跳转唤起app协议地址
      setTimeout(function() {
        location.href = appGoUrl;//ios协议启动地址
      }, 96);
      //延迟执行 如果1s没响应，就表示你手机中没有该app。就可以去下载了
      setTimeout(function() {
        if (Date.now() - t < 1200) {
          clickFlag = true;
          let msierDomain = process.env.VUE_APP_msierDomain;
          window.location.href = `${msierDomain}/mobile/app/download`;
        }
      }, 1001);
    }
  },
  deepClone: function(source) {
    if (!source && typeof source !== 'object') {
      throw new Error('error arguments', 'deepClone')
    }
    const targetObj = source.constructor === Array ? [] : {}
    Object.keys(source).forEach(keys => {
      if (source[keys] && typeof source[keys] === 'object') {
        targetObj[keys] = this.deepClone(source[keys])
      } else {
        targetObj[keys] = source[keys]
      }
    })
    return targetObj
  },
  wxViewJump: function(url) {
    wx.miniProgram.redirectTo({
      url: url,
      success: (res) => {
        console.log(res);   // 页面跳转成功的回调函数
      },
      fail: (err) => {
        console.log(err);   // 页面跳转失败的回调函数
      },
    });
  },
  wxPostMessage:function (data){
    wx.miniProgram.postMessage({ data: data })

  },
  handleWxConfig: function() {
    let params = {
      "url": window.location.href,
    }
    return new Promise((resolve, reject) => {
      WeChatSignature(params).then(res => {
        console.log(params)
        if (res.code == 0) {
          let entity = res.entity;
          // 配置
          wx.config({
            debug: false,
            appId: entity.appId,
            timestamp: entity.timestamp,
            nonceStr: entity.nonceStr,
            signature: entity.signature,
            jsApiList: ['showOptionMenu', 'chooseImage', 'previewImage'],
            openTagList: ['wx-open-launch-weapp'],
          });

          wx.ready(function() {
            resolve('continue')
            console.log('ready success')
          });
          wx.error(function() {
            console.log('error,fail')
          })
        } else {
          console.log('接口调用失败');
        }
      })
    })

  },

};
